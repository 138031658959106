import { navigate as gatsbyNav } from 'gatsby'
import { Moment } from 'moment'
import moment from 'moment-with-locales-es6'

import { Artist } from './app.types'
import { Lang } from './i18n/i18n'

export const genres: GenreType[] = ['rock', 'jazz']

export const genresData = {
	rock: {
		apiId: '1',
		url_friendly_identifier: 'rock_indie_electronic',
	},
	jazz: {
		apiId: '2',
		url_friendly_identifier: 'jazz_world',
	},
}

export type GenreType = keyof typeof genresData

export type GenreTypeDisplayText = 'rock_indie_electronic' | 'jazz_world'

// routes
export const artistPath = (lang: Lang, artist: Artist | string) => {
	const url_friendly_identifier =
		typeof artist === 'string' ? artist : artist.url_friendly_identifier
	return `/${lang}/artist/${url_friendly_identifier}`
}
export const artistsPath = (lang: Lang, genreType: GenreType) => {
	const genreDisplayText =
		genreType === 'rock' ? 'rock_indie_electronic' : 'jazz_world'
	return `/${lang}/lineup/${genreDisplayText}`
}
export const baseUrl = process.env.BASE_URL

export function programPagePath({
	lang = 'en',
	date,
	genreType,
}: {
	lang: Lang
	date: Moment
	genreType: GenreType
}) {
	return `/${lang}/program/${genreTypeToDisplayText(genreType)}/${date}/`
}

export const navigate = (lang: Lang, to: string) => {
	gatsbyNav(`/${lang}` + to)
}

export const xorGenreType = (genreType: GenreType) =>
	genreType === 'rock' ? 'jazz' : 'rock'

export function genreTypeToDisplayText(genreType: GenreType) {
	return genreType === 'rock' ? 'rock_indie_electronic' : 'jazz_world'
}

export function tobase64(obj: any) {
	if (typeof window !== 'undefined') return btoa(obj)
	return Buffer.from(obj).toString('base64')
}

export function frombase64(obj: any) {
	if (typeof window !== 'undefined') return atob(obj)
	return Buffer.from(obj, 'base64').toString()
}

export function formatValue(date: Moment, lang: Lang = 'en') {
	return moment(date)
		.locale(lang)
		.format('DD.MM')
}

export function formatDisplayText(date: Moment, lang: Lang = 'en') {
	return moment(date)
		.locale(lang)
		.format('DD.MM dddd')
}

export function formatH2(date: Moment, lang: Lang = 'en') {
	return moment(date)
		.locale(lang)
		.format('dddd DD.MM')
}

export function formatShowBox(date: Moment, lang: Lang = 'en') {
	return moment(date)
		.locale(lang)
		.format('DD/MM')
}

export function formatRegular(date: Moment, lang: Lang = 'en') {
	return moment(date)
		.locale(lang)
		.format('DD/MM/YYYY')
}
