import React from 'react'

export type Lang = 'he' | 'en'
export const langs: Lang[] = ['en', 'he']

const h = 'כאן יהיה טקסט בעברית'
const res = {
	'lineup.seo.title': ['Lineup', 'רשימת אמנים'],
	'lineup.header': ['2019 LINE-UP', 'רשימת אמנים'],
	'lineup.discover': ['Discover', 'מידע נוסף'],
	'lineup.tickets': ['Tickets', 'כרטיסים'],
	'common.genre.rockLong': ['Rock, Indie & Electronic', 'רוק, אינדי ואלקטרוני'],
	'common.genre.jazzLong': ['Jazz & World', "ג'אז ועולם"],
	'header.home': ['Home', 'ראשי'],
	'header.program': ['Program', 'תוכניה'],
	'header.artists': ['Artists', 'אמנים'],
	'header.guests': ['Guests', 'אורחים'],
	'header.showcase': ['Showcase', 'מקרי הצלחה'],
	'header.about': ['About', 'על הפסטיבל'],
	'header.press': ['Press', 'מן העיתונות'],
	'header.lineup': ['Lineup', 'אמנים'],
	'header.talk': ['Talk/Show/Case', 'Talk/Show/Case'],
	'home.aprodof': ['A Production of', 'הפקה של'],
	'home.mrworldwide': [
		'2019 INTERNATIONAL MUSIC SHOWCASE FESTIVAL',
		'פסטיבל חשיפה בינלאומית למוזיקה 2019',
	],
	'home.desc': [
		`The International Music Showcase Festival is celebrating 10 years of exporting and promoting some of the finest Israeli music to the world. For an entire decade The Showcase Festival has birthed international cooperation, and seen many Israeli artists and groups going off to perform all over the globe: Major music festivals in Barcelona, England, Bosnia, Taiwan, Russia, China and India; on tours all over Europe in Germany, France, Italy, Austria, Turkey and more.`,
		`פסטיבל החשיפה הבינלאומית חוגג עשור בקידום ייצוא המוזיקה הישראלית לחו"ל. כבר עשר שנים שכל שנה בעקבות הפסטיבל, הרכבים ולהקות מישראל יוצאים להופיע ברחבי העולם. בפסטיבלי ענק בברצלונה, אנגליה, בוסניה, טייוואן, רוסיה, סין והודו. מסעי הופעות נתפרים ברחבי אירופה: גרמניה, צרפת, איטליה, אוסטריה, תורכיה ועוד.`,
	],
	'home.lineupAnnounced': ['lineup announced!', 'רשימת האמנים נחשפה!'],
	'home.bottomDesc': [
		`We would like to thank more than 500 artists who applied, and excited
					to reveal those who made it to this years’ festival lineup`,
		`אנחנו מודים ליותר מ - 500 אמנים שנרשמו לפסטיבל השנה, ושמחים לחשוף את אלה שישתתפו באירועי החשיפה לשנת 2019`,
	],
	'home.explorerock': [
		'Explore Rock, Indie & Electronic',
		'אמני רוק, אינדי ואלקטרוני',
	],
	'home.explorejazz': ['Explore Jazz & World', `אמני ג׳אז ועולם`],
	'artist.info': ['INFO', 'מידע'],
	'artist.bandMembers': ['BAND MEMBERS', 'חברי ההרכב'],
	'artist.listen': ['LISTEN', 'מוזיקה'],
	'artist.artists': ['Artists', 'אמנים'],
	'guests.seo.title': ['Guests', 'אורחים'],
	'guests.header': ['FESTIVAL GUESTS', 'אורחי הפסטיבל'],
	'guests.card.readmore': ['More Info', 'המשך קריאה'],
	'guests.card.readless': ['Less Info', 'צמצום'],
	'guests.card.website': ['Website', 'אתר'],
	'program.seo.title': ['Program', 'תוכניה'],
	'program.title': ['PROGRAM', 'תוכניה'],
	'program.daysession': ['DAY SESSION', 'סשן יום'],
	'program.nightsession': ['NIGHT SESSION', 'סשן לילה'],
	'program.sessioncard.artistpage': ['Artist Page', 'לעמוד האמן'],
	'program.sessioncard.buytickets': ['BUY TICKETS', 'רכישת כרטיסים'],
	'about.menu.about': ['ABOUT', 'אודות'],
	'about.menu.festivalteam': ['FESTIVAL TEAM', 'צוות הפסטיבל'],
	'about.menu.pastfestivals': ['PAST FESTIVALS', 'פסטיבלי עבר'],
	'about.menu.partners': ['PARTNERS', 'שותפים'],
	'about.header': [
		'INTERNATIONAL MUSIC SHOWCASE FESTIVAL',
		'פסטיבל חשיפה בינלאומית למוזיקה',
	],
	'about.header.desc': [
		`
		<p>The International Music Showcase Festival is celebrating 10 years of exporting and promoting some of the finest Israeli music to the world.</p>
		<p><b>Music showcase festivals take place all over the globe, aimed at sharing local music with top representatives from the global music industry</b>.</p> 
		
		<p><b>Israel's</b> musical showcase is produced by the Yellow Submarine, and is thought to be <b>one of the finest in the world. With a distinguished musical scene and an enchanting Mediterranean atmosphere, everyone wants a piece of that.</b></p>
		
		<p>Every year in November, dozens of music industry professionals arrive from all over the world. Among them are major festival directors, club and venue owners, producers, artistic directors, record company reps, journalists, reporters and bloggers, radio hosts, and international talent scouts. These come from all ends of the globe to see, hear and experience as much as they can of the Israeli contemporary music scene.</p>
		
		<p>The festival divides up into 2 weekends: The first showcasing Rock, Indie, and Electronic music acts; the second dedicated to Jazz, Funk, and World music.
		
		Over a span of 8 days the festival holds around 60 shows from the finest artists and groups Israel has to offer, in a broad range of styles and genres.</p>
		
		<p>Our international guests go on an in-depth, exciting tour of the Israeli music scene.</p>
		
		<p>Most of the festival’s performances take place in the Yellow Submarine in Jerusalem, as well as in a number of venues around town; on Friday the festival migrates down the hill, to get a taste of the vibrant live music scene in Tel Aviv.</p>		
		`,
		`
		<p>
		פסטיבל החשיפה הבינלאומית חוגג עשור בקידום ייצוא המוזיקה הישראלית לחו"ל.
		</p>  

		<p>
		פסטיבלי חשיפה <b>{Showcase}</b> למוזיקה מתקיימים ברחבי העולם ומטרתם לחשוף את המוזיקה המקומית בפני נציגים בכירים בתעשיית המוזיקה העולמית.
		</p>
		<p>
		בישראל מתקיים פסטיבל החשיפה הבינלאומית למוזיקה בהפקת הצוללת הצהובה ונתפס כאחד הטובים בעולם. עם סצנה מוזיקלית ברמה גבוהה ואווירה ים תיכונית כובשת, כולם רוצים חתיכה מאיתנו.  
		</p>
		<p>
		בכל שנה בחודש נובמבר מגיעים לישראל עשרות מאנשי תעשיית המוזיקה מרחבי העולם:  מנהלי פסטיבלי ענק, בעלי מועדונים גדולים וקטנים, מפיקים, מנהלים אומנותיים, חברות תקליטים, עיתונאים, כתבים, שדרני רדיו וצידיי כישרונות בינלאומיים. מכל רחבי העולם הם מגיעים לראות, לשמוע ולחוות כמה שיותר מהסצנה המוזיקלית בישראל.
		</p>

		<p>
		הפסטיבל מתחלק לשני סופי שבוע: האחד לרוקנרול, אינדי ומוזיקה אלקטרונית, והשני מוקדש לג'אז, מוזיקת עולם וFאנק.
		 במשך 8 ימים מתקיימות כ-60 הופעות מגוונות של מיטב ההרכבים, אמנים/ות ולהקות ישראליות בפני האורחים מחו"ל. 
		 
		האורחים עוברים מסע עצמתי וחווייתי סביב סצנת המוזיקה הישראלית.
		</p>
		<p>
		רוב ההופעות מתקיימות בצוללת הצהובה בירושלים ובמועדונים שונים בעיר; 
		בימי שישי יורד הפסטיבל להרגיש את סצנת ההופעות החיות והמועדונים בתל אביב.  
		</p>  		
		`,
	],
	'about.artisticcommitees': ['ARTISTIC COMMITEES', 'ועדות אמנותיות'],
	'about.artisticcommitees.desc': [
		`The artists in the Showcase are selected by artistic committees composed of Israeli and worldwide representatives from the music community. Journalists, media professionals, music festival directors, exposure festival directors and more.`,
		'האמנים המופיעים בחשיפה נבחרים ע"י ועדות אמנותיות המורכבות מנציגים ישראלים ונציגים מרחבי העולם, כולם מקהילת המוזיקה. עיתונאים, אנשי תקשורת, מנהלי פסטיבלי מוזיקה, מנהלי פסטיבלי חשיפה ועוד.',
	],
	'about.visitlastyear': [
		"Visit last years' showcase festival websites",
		'צפו באתרים של פסטיבלי חשיפה משנים קודמות',
	],
	'about.ourpartners': ['OUR PARTNERS', 'השותפים שלנו'],
	'talk.menu.about': ['About', 'אודות'],
	'talk.menu.register': ['Register', 'הירשמו'],
	'talk.menu.braindates': ['Brain Dates', 'Brain Dates'],
	'talk.menu.speakers': ['Speakers', 'דוברים'],
	'talk.menu.program': ['Program', 'תוכנייה'],
	'talk.schedule': ['Schedule a meeting', 'קבעו פגישה'],
}

type LangKey = keyof typeof res

export const defaultLang = 'en'

export const LangContext = React.createContext<{
	lang: Lang
	t: (key: LangKey) => string
}>({
	lang: defaultLang,
	t: (key: LangKey): string => res[key][1],
})

export const LangProvider = ({
	children,
	pageContext: { lang = defaultLang } = { lang: defaultLang },
}) => {
	return (
		<LangContext.Provider
			value={{
				lang: lang as Lang,
				t: (key: LangKey): string => res[key][langs.findIndex(l => l === lang)],
			}}
		>
			<div className={'app-wrapper lang-' + lang}>{children}</div>
		</LangContext.Provider>
	)
}

export function wrapLangProvider<T>(Cls: React.FC<T>) {
	return (props: T) => (
		<LangProvider {...props}>
			<Cls {...props} />
		</LangProvider>
	)
}
