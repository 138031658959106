import React from 'react'
import classnames from 'classnames'
import { Layout } from '../../components/layout'
import SEO from '../../components/seo'
import { GenreType } from '../../common'
import { wrapLangProvider } from '../../i18n/i18n'

import './HeaderMenuPage.scss'
export type HeaderMenuPageProps = {
	headerText: string
	menu: any
	seoTitle: string
	className: string
	genreType: GenreType
}

export const HeaderMenuPage: React.FC<HeaderMenuPageProps> = ({
	headerText,
	menu: Menu,
	seoTitle,
	className,
	genreType,
	children,
}) => {
	return (
		<Layout className={classnames('header-menu-page', genreType, className)}>
			<SEO title={seoTitle} />
			<div className="content">
				<header>
					<div className="headerText">{headerText}</div>
					<Menu />
				</header>
				<div className="renderProp">{children}</div>
			</div>
		</Layout>
	)
}
