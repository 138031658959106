import './Header.scss'

import { Drawer, DrawerContent } from '@rmwc/drawer'
import { IconButton } from '@rmwc/icon-button'
import { List, ListItem } from '@rmwc/list'
import { navigate } from 'gatsby'
import React, { useContext } from 'react'

import { LangContext } from '../../i18n/i18n'
import { BuyTicketsModalContext } from '../BuyTickesModal/BuyTickesModal'
import { MyLink } from '../MyLink/MyLink'
import { MaterialButton } from '../react-button'
import logo_en from './logo_en.png'

export const Header = () => {
	const { lang, t } = useContext(LangContext)
	const { setShow } = useContext(BuyTicketsModalContext)
	const [navigationOpen, setNavigationOpen] = React.useState(false)
	const logo = lang == 'en' ? logo_en : '' // TODO
	const menu = [
		{ text: t('header.home'), to: `/${lang}` },
		{ text: t('header.about'), to: `/${lang}/about` },
		{ text: t('header.program'), to: `/${lang}/program` },
		{
			text: t('header.lineup'),
			to: `/${lang}/lineup`,
			partiallyActive: true,
		},
		// { text: t('header.press'), to: `/${lang}/press` },
		{ text: t('header.guests'), to: `/${lang}/guests` },
		{ text: t('header.talk'), to: `/${lang}/talk-show-case` },
		// { text: t('header.showcase'), to: `/${lang}/showcase` },
	]
	return (
		<header className="app-header">
			<Drawer
				dir={lang == 'he' ? 'rtl' : 'ltr'}
				modal
				open={navigationOpen}
				onClose={() => setNavigationOpen(false)}
			>
				<DrawerContent>
					<List>
						{menu.map((item, index) => (
							<ListItem key={index} onClick={() => navigate(item.to)}>
								{item.text}
							</ListItem>
						))}
					</List>
				</DrawerContent>
			</Drawer>
			<a href={`/${lang}`} className="app-header__logo-anchor">
				<img
					src={logo_en}
					alt="International Music Showcase Festival 2019"
					className="app-header__logo"
				/>
			</a>

			<div className="app-header__navigation-container">
				<nav className="app-header__navigation app-header__navigation--desktop">
					{menu.map((item, index) => (
						<MyLink
							activeClassName="active"
							partiallyActive={item.partiallyActive}
							to={item.to}
							key={index}
						>
							{item.text}
						</MyLink>
					))}
				</nav>
				<div className="app-header__tools">
					<IconButton
						icon="menu"
						className="app-header__tools-toggle-nav-button"
						onClick={() => setNavigationOpen(!navigationOpen)}
					/>
					<MaterialButton
						className="app-header__tickets-button button button-prefill rock"
						onClick={() => setShow(true)}
					>
						{t('lineup.tickets')}
					</MaterialButton>
					<MaterialButton
						onClick={() => {
							// we're on a content page - content pages always have lang identifier in location
							if (
								['he', 'en'].find(
									d => window.location.href.indexOf('/' + d) !== -1
								)
							)
								window.location.href = window.location.href.replace(
									lang == 'en' ? '/en' : '/he',
									lang == 'en' ? '/he' : '/en'
								)
							// we're on the front page, which doesn't have a 301 to ${ROOT}/en
							else window.location.href = window.location.href + 'he'
						}}
					>
						{lang == 'en' ? 'עברית' : 'English'}
					</MaterialButton>
				</div>
			</div>
		</header>
	)
}
