import React, { useState, FC } from 'react'
import { Link, navigate } from 'gatsby'
import { MaterialButton } from '../react-button'

export interface MyLinkProps {
	type?: string
	href?: string
	to?: string
	className?: string
	activeClassName?: string
	partiallyActive?: boolean
	onClick?: (event: any) => void
}

export const MyLink = (
	{
		type = 'primary', // or secondary
		href = '',
		to = '',
		className = '',
		activeClassName = '',
		partiallyActive = false,
		children,
		onClick,
		...args
	}: any /* TODO */
) => {
	return (
		<MaterialButton
			onClick={e => {
				if (onClick) onClick(e)
				if (to) navigate(to)
			}}
			href={href}
			className={className}
			{...args}
		>
			{children}
		</MaterialButton>
	)
}
