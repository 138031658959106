import './ProgramPage.scss'
import '@material/react-list/index.scss'
import '@material/react-menu-surface/index.scss'
import '@material/react-menu/index.scss'
import '@material/react-select/index.scss'

import { Select } from '@rmwc/select'
import classnames from 'classnames'
import moment, { Moment } from 'moment'
import React, { useContext, useState } from 'react'

import { formatDisplayText, formatH2, GenreType } from '../../common'
import { ColumnMenu, ColumnMenuButton } from '../../components/ColumnMenu/ColumnMenu'
import { Layout } from '../../components/layout'
import SEO from '../../components/seo'
import { SessionCard, SessionData } from '../../components/SessionCard/SessionCard'
import { LangContext, wrapLangProvider } from '../../i18n/i18n'
import { GatsbyPageProps, GatsbyPageWrapper } from '../../types'

export type SessionsByLabel = {
	label: string
	sessions: SessionData[]
}
export type SessionByDate = {
	dayObj: SidebarDate
	sessionsByLabel: SessionsByLabel[]
}

export type Props = {
	sessionsByDate: SessionByDate[]
}

export type PageProps = GatsbyPageProps<Props>

export const ProgramPage = wrapLangProvider(
	({ pageContext: { sessionsByDate } }: GatsbyPageWrapper<Props>) => {
		const { lang, t } = useContext(LangContext)
		const dates = sessionsByDate.map(d => d.dayObj)
		const [session, setSessionByDate] = useState(
			sessionsByDate.find(d => d.dayObj.genreType === 'rock')
		)
		const {
			dayObj: { date, genreType },
			sessionsByLabel,
		} = session
		const onChange = (d: SidebarDate) =>
			setSessionByDate(
				sessionsByDate.find(dd => {
					return dd.dayObj === d
				})
			)
		return (
			<Layout className={classnames('program-page')}>
				<SEO title={t('program.seo.title')} />
				<div className="program-page__content grid">
					<Sidebar className="onlyDesktop" dates={dates} onChange={onChange} />
					<div className="program-page__rightside">
						<h2 className="onlyMobile headerText">{t('program.seo.title')}</h2>
						<SelectDate
							onChange={onChange}
							dates={dates}
							className="program-page__select-date onlyMobile"
						/>
						<h2 className="onlyDesktop headerText">{formatH2(date, lang)}</h2>
						<div className="program-page__rightside-content">
							{sessionsByLabel.map(({ label, sessions }, key) => (
								<SessionGroup
									key={key}
									genreType={genreType}
									sessions={sessions}
									sessionBoxHeader={label}
								/>
							))}
						</div>
					</div>
				</div>
			</Layout>
		)
	}
)
type OnChange = (currentValue: SidebarDate) => void
export type SidebarDate = {
	date: moment.Moment
	genreType: GenreType
}

export function Sidebar({
	dates: rawDates,
	onChange: parentOnChange,
	className,
}: {
	dates: SidebarDate[]
	className?: string
	onChange?: OnChange
}) {
	const { lang, t } = useContext(LangContext)

	const rock: ColumnMenuButton<SidebarDate>[] = rawDates
		.filter(button => button.genreType === 'rock')
		.map(button => ({
			value: button,
			displayText: formatDisplayText(button.date, lang),
		}))
	const jazz: ColumnMenuButton<SidebarDate>[] = rawDates
		.filter(button => button.genreType === 'jazz')
		.map(button => ({
			value: button,
			displayText: formatDisplayText(button.date, lang),
		}))
	const [dateValue, setSidebar] = useState(rock[0])
	const onChange = (value: SidebarDate) => {
		setSidebar(
			[...rock, ...jazz].find(button => {
				return button.value === value
			})
		)
		if (parentOnChange) parentOnChange(value)
	}

	return (
		<div className={classnames('program-page__sidebar', className)}>
			<h1>{t('program.title')}</h1>
			<div className="program-page__sidebar-rock">
				<div className="program-page__sidebar-header body1">
					{t('common.genre.rockLong')}
				</div>
				<ColumnMenu
					className="rock"
					buttons={rock}
					value={dateValue.value}
					onChange={onChange}
				/>
			</div>
			<div className="program-page__sidebar-jazz">
				<div className="program-page__sidebar-header body1">
					{t('common.genre.jazzLong')}
				</div>
				<ColumnMenu
					className="jazz"
					buttons={jazz}
					value={dateValue.value}
					onChange={onChange}
				/>
			</div>
		</div>
	)
}

export function SessionGroup({
	sessionBoxHeader,
	sessions,
	genreType,
	className,
}: {
	sessionBoxHeader: string
	sessions: SessionData[]
	genreType: GenreType
	className?: string
}) {
	console.log(sessions);
	return (
		<div className={classnames('program-page__sessionbox', className)}>
			<h6 className="program-page__sessionbox-header">{sessionBoxHeader}</h6>
			<div className="program-page__sessionbox-grid">
				{sessions.map((session, index) => (
					<SessionCard
						locationPlaceName={session.locationPlaceName}
						genreType={genreType}
						locationGeoName={session.locationGeoName}
						shows={session.shows}
						key={index}
						entrance_link={session.entrance_link}
					/>
				))}
			</div>
		</div>
	)
}

export const SelectDate = ({
	dates,
	onChange,
	className,
}: {
	dates: SidebarDate[]
	onChange: OnChange
	className?: string // TODO refactor to a BaseReactProps
}) => {
	const { lang, t } = useContext(LangContext)

	const rock = {
		label: t('common.genre.rockLong'),
		options: [] as { label: string; value: string }[],
	}

	const jazz = {
		label: t('common.genre.jazzLong'),
		options: [] as { label: string; value: string }[],
	}

	dates.forEach((date: SidebarDate) => {
		if (date.genreType === 'rock')
			rock.options.push({
				label: formatDisplayText(date.date, lang),
				value: 'rock--' + date.date.toString(),
			})
		else
			jazz.options.push({
				label: formatDisplayText(date.date, lang),
				value: 'jazz--' + date.date.toString(),
			})
	})

	const [state, setState] = useState(rock.options[0].value)
	return (
		<Select
			enhanced
			options={[rock, jazz]}
			className={className}
			value={state}
			onChange={(
				{
					detail: { index, value },
				}: any /* is not React.FormEvent but CustomEvent, their madeup type. */
			) => {
				setState(value)
				const [genreType, date] = value.toString().split('--')
				onChange(
					dates.find(
						d =>
							moment(d.date).isSame(moment(date)) && d.genreType === genreType
					)
				)
			}}
		/>
	)
}

export default ProgramPage
