import React, { useState, useContext, useEffect, useRef } from 'react'
import { Artist, Guest } from '../../app.types'
import { GenreType } from '../../common'
import classnames from 'classnames'
import { LangContext } from '../../i18n/i18n'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import './GuestCard.scss'
import { MaterialButton } from '../react-button'

interface GuestCardProps {
	guest: Guest
}

export const GuestCard = ({
	guest: {
		name,
		industryRole,
		companyName: industryLocation,
		description,
		pictureURL,
		country,
		websiteLink,
		genreType,
	},
}: GuestCardProps) => {
	const { lang, t } = useContext(LangContext)
	const [isOpen, setOpenTo] = useState(false)
	const ref = useRef<HTMLDivElement>()
	useEffect(() => {
		if (isOpen) {
			ref.current.style.height = ref.current.scrollHeight + 'px'
		} else {
			ref.current.style.height = ''
		}
	}, [isOpen])

	return (
		<div
			className={classnames('guest-card card', genreType, {
				'guest-card_open': isOpen,
				'guest-card_closed': !isOpen,
			})}
			ref={ref}
		>
			<div className="guest-card__topbar body1">
				<div className="guest-card__topbar-left">
					{country && <div className="guest-card__topbar-country">
						<img
							className="guest-card__topbar-country-image"
							src={findFlagUrlByIso2Code(country.code)}
						/>
						{country && <div className="guest-card__topbar-country-name">
							{country.name}
						</div>}
					</div>}
					<div className="guest-card__topbar-left-name">{name}</div>
					<div className="guest-card__topbar-left-undername body1">
						{industryRole}{industryLocation && `, ${industryLocation}`}
					</div>
				</div>
				<div className="guest-card__topbar-right">
					<img className="guest-card__profile" src={pictureURL} />
				</div>
			</div>
			<div
				className="guest-card__description"
				style={{ display: isOpen ? 'inherit' : 'none' }}
			>
				{description}
			</div>

			<div className="guest-card__bottom">
				<MaterialButton
					className="guest-card__moreinfo"
					onClick={() => setOpenTo(!isOpen)}
				>
					{t(isOpen ? 'guests.card.readless' : 'guests.card.readmore')}
				</MaterialButton>
				{ websiteLink && 
					<MaterialButton className="guest-card__website">
						<a href={websiteLink} target="_blank">
							{t('guests.card.website')}
						</a>
					</MaterialButton>
				}

			</div>
		</div>
	)
}
