import React, { useState, useContext } from 'react'
import { GenreType } from '../../common'
import { ColumnMenuRockJazz } from '../../components/ColumnMenu/ColumnMenu'
import { Guest } from '../../app.types'
import { HeaderMenuPage } from '../HeaderMenuPage/HeaderMenuPage'
import { GuestCard } from '../../components/GuestCard/GuestCard'
import { GatsbyPageProps, GatsbyPageWrapper } from '../../types'
import { wrapLangProvider, LangContext } from '../../i18n/i18n'

import './GuestsPage.scss'
import '../../components/GuestsGroup/GuestsGroup.scss'

export interface Props {
	initialGenreType?: GenreType
	guests: Guest[]
}

export type PageProps = GatsbyPageProps<Props>

export const GuestsPage = wrapLangProvider(
	({ pageContext: { initialGenreType, guests } }: GatsbyPageWrapper<Props>) => {
		const { lang, t } = useContext(LangContext)
		const [genreType, setGenreType] = useState(initialGenreType)

		return (
			<HeaderMenuPage
				seoTitle={t('guests.seo.title')}
				className="guests-page"
				headerText={t('guests.header')}
				genreType={genreType}
				menu={() => (
					<ColumnMenuRockJazz value={genreType} setChecked={setGenreType} />
				)}
			>
				<div className="guests-page__guests">
					{guests
						.filter(g => g.genreType === genreType)
						.map((guest, index) => {
							return <GuestCard guest={guest} key={index} />
						})}
				</div>
			</HeaderMenuPage>
		)
	}
)

export default GuestsPage
