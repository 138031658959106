import React, { useState, useContext } from 'react'
import classnames from 'classnames'

import { Link } from 'gatsby'
import { MyLink } from '../../components/MyLink/MyLink'
import { LangContext } from '../../i18n/i18n'
import { MaterialButton } from '../../components/react-button'
import { BuyTicketsModalContext } from '../../components/BuyTickesModal/BuyTickesModal'

export const LineupBox = ({
	name,
	backgroundImage,
	url_friendly_identifier,
	ticketsLink,
	genreType,
}) => {
	const { lang, t } = useContext(LangContext)
	const { setShow } = useContext(BuyTicketsModalContext)
	return (
		<Link to={url_friendly_identifier} className="lineupBox__link-container">
		<div className="lineupBox">
			<div className="name">{name}</div>
			<div className="button-row">
				<MyLink
					to={url_friendly_identifier}
					className={classnames(genreType, 'link artistLink')}
				>
					{t('lineup.discover')}
				</MyLink>
				<MaterialButton
					onClick={() => setShow(true)}
					className={classnames(genreType, 'link ticketsLink')}
				>
					{t('lineup.tickets')}
				</MaterialButton>
			</div>
			<img src={backgroundImage} />
		</div>
		</Link>

	)
}
