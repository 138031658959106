import './ArtistPage.scss'

import { Button } from '@rmwc/button'
import classnames from 'classnames'
import { Link, navigate } from 'gatsby'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { Artist as ArtistType, ArtistShow } from '../../app.types'
import apple_music from '../../assets/images/social/apple_music'
import facebook from '../../assets/images/social/facebook'
import instagram from '../../assets/images/social/instagram'
import sound_cloud from '../../assets/images/social/sound_cloud'
import spotify from '../../assets/images/social/spotify'
import twitter from '../../assets/images/social/twitter'
import website from '../../assets/images/social/website'
import { artistPath, artistsPath, baseUrl, genresData } from '../../common'
import { BuyTicketsModalContext } from '../../components/BuyTickesModal/BuyTickesModal'
import { ColumnMenu, ColumnMenuButton } from '../../components/ColumnMenu/ColumnMenu'
import { Layout } from '../../components/layout'
import { MaterialButton } from '../../components/react-button'
import SEO from '../../components/seo'
import { LangContext, wrapLangProvider } from '../../i18n/i18n'
import { GatsbyPage, GatsbyPageProps } from '../../types'
import { ShowBox as ShowsBox, ShowBoxShort as ShowsBoxShort } from './ShowBox'

export type Props = {
	artist: ArtistType
	artistShow?: ArtistShow
	nextShows: ArtistShow[]
	nextArtist: { name: string; url: string }
	prevArtist: { name: string; url: string }
}

export type PageProps = GatsbyPageProps<Props>

export const ArtistPage: GatsbyPage<Props> = wrapLangProvider(
	({
		pageContext: {
			artist: {
				name = '',
				description = '',
				genreType,
				cover_picture_absolute_path,
				socialLinks = [],
				band_members: bandMembers,
				videoLinks,
			},
			artistShow,
			nextShows,
			nextArtist,
			prevArtist,
		},
	}) => {
		const { lang, t } = useContext(LangContext)
		const { setShow } = useContext(BuyTicketsModalContext)
		const [value, setValue] = useState('info')
		const buttons: ColumnMenuButton<string>[] = [
			{ value: 'info', displayText: t('artist.info') },
			{ value: 'bandMembers', displayText: t('artist.bandMembers') },
			{ value: 'listen', displayText: t('artist.listen') },
		]
		const refs = {
			info: useRef<HTMLDivElement>(),
			bandMembers: useRef<HTMLDivElement>(),
			listen: useRef<HTMLDivElement>(),
		}
		const columnMenuOnChange = columnMenuScrollToEventHandlerCreator(refs)

		useEffect(() => {
			const eventHandler = getScrollToEventHandler(
				[
					{ refName: 'info', ref: refs.info },
					{ refName: 'bandMembers', ref: refs.bandMembers },
					{ refName: 'listen', ref: refs.listen },
				],
				setValue
			)

			window.addEventListener('scroll', eventHandler)
			return () => {
				window.removeEventListener('scroll', eventHandler)
			}
		}, [])
		const genre = genresData[genreType]

		const buyTickets = (
			<MaterialButton
				className={classnames(
					'getTicketsLink button button-prefill',
					genreType
				)}
				onClick={() => setShow(true)}
			>
				{t('program.sessioncard.buytickets')}
			</MaterialButton>
		)
		let wideBoxes = artistShow && (
			<ShowsBox
				genreType={genreType}
				date={moment(artistShow.date)}
				sessionName={artistShow.sessionName}
				sessionType={artistShow.sessionType}
				nextShows={nextShows}
			/>
		)
		let mobileBoxes = artistShow && (
			<ShowsBoxShort
				genreType={genreType}
				date={moment(artistShow.date)}
				sessionName={artistShow.sessionName}
				sessionType={artistShow.sessionType}
			/>
		)

		return (
			<Layout className={classnames('artist-page', { [genreType]: true })}>
				<SEO title={name} />
				<div className="artist-background-wrapper">
					<div className="black"></div>
					<div
						className="artist-background"
						style={{ backgroundImage: `url("${cover_picture_absolute_path}")` }}
					></div>
				</div>
				<div className="artist-page__container grid">
					<div className="artist-page__column-menu-container">
						<ColumnMenu
							className={classnames(genreType, 'artist-page__column-menu')}
							onChange={columnMenuOnChange}
							value={value}
							buttons={buttons}
						/>
					</div>
					<div className="artist-page__content">
						<Link
							to={artistsPath(lang, genreType)}
							className={classnames(genreType, 'artist-page__breadcrumbs')}
						>
							<div className="artist-page__breadcrumbs-link">
								{t('artist.artists')}
							</div>
							<div>&nbsp;>&nbsp;</div>
							<div className="artist-page__breadcrumbs-link">
								{t(
									genreType === 'rock'
										? 'common.genre.rockLong'
										: 'common.genre.jazzLong'
								)}
							</div>
						</Link>
						<div className="coverImage">
							<img src={cover_picture_absolute_path} />
							<div className="gradientSurface">
								{socialLinks.map(({ type, url }) => (
									<a href={url} target="_blank" key={url}>
										<Icon type={type} />
									</a>
								))}
							</div>
						</div>
						<h1 className="artist-page__title" id="info" ref={refs.info}>
							{name.toString().toUpperCase()}
						</h1>
						<div
							className="artist-page__description"
							dangerouslySetInnerHTML={{
								__html: description.replace(/\n/g, '<br />'),
							}}
						></div>
						<div
							className="artist-page__bandmembers"
							id="bandmembers"
							ref={refs.bandMembers}
						>
							{bandMembers.map(({ name, role }) => (
								<div className="bandmembers" key={name}>
									<div className="bandmember-name overline">{role}</div>
									<div className="bandmember-role">{name}</div>
								</div>
							))}
						</div>
						<div
							className="artist-page__videolinks"
							id="listen"
							ref={refs.listen}
						>
							{videoLinks.filter(Boolean).map(src => (
								<iframe
									key={src}
									src={
										src.replace(
											'/watch?v=',
											'/embed/'
										) /* TODO move this to server */ +
										'?origin=' +
										baseUrl
									}
								/>
							))}
						</div>
					</div>
				</div>
				<div className="artist-page__footer-navigation">
					<Button
						label={prevArtist.name}
						icon="chevron_left"
						onClick={() => {
							navigate(artistPath(lang, prevArtist.url))
						}}
					/>
					<Button
						label={nextArtist.name}
						trailingIcon="chevron_right"
						onClick={() => {
							navigate(artistPath(lang, nextArtist.url))
						}}
					/>
				</div>
				{/*
				<div className="artist-page__fixed-footer-container onlyDesktop">
					<div className="artist-page__fixed-footer">{wideBoxes}</div>
				</div>
				<div className="artist-page__fixed-footer onlyMobile">
					{mobileBoxes}
				</div>
					*/}
			</Layout>
		)
	}
)

const Icon = ({ type }) => {
	const icons = {
		website,
		instagram,
		facebook,
		sound_cloud,
		apple_music,
		spotify,
		twitter,
	}
	const Icon = icons[type]
	if (!Icon)
		console.warn(`Artist has ${type} but we dont have an icon for it...`)
	return <div className="icon">{Icon && <Icon />}</div>
}

export type RefStore = {
	[refName: string]: React.RefObject<HTMLElement>
}

export function columnMenuScrollToEventHandlerCreator(refs: RefStore) {
	return (refName: string) => {
		const ele = refs[refName].current

		if (ele)
			window.scrollTo({
				top: getOffsetTop(ele),
				behavior: 'smooth',
			})
	}
}

export function getScrollToEventHandler(
	refs: { refName: string; ref: React.RefObject<HTMLElement> }[],
	onScroll: (refName: string) => void,
	mekadem = window.outerHeight / 2
) {
	return (event: MouseEvent) => {
		const y = window.pageYOffset + mekadem

		let i = 0
		for (; i < refs.length - 1; i++) {
			const { ref, refName } = refs[i + 1]
			const offsetTop = getOffsetTop(ref.current)
			// console.log(refName, offsetTop, y) // DEBUG
			if (offsetTop > y) {
				break
			}
		}
		onScroll(refs[i].refName)
	}
}

function getOffsetTop(ele: HTMLElement) {
	if (!ele) debugger
	var { offsetParent, offsetTop } = ele
	while (offsetParent !== document.body) {
		offsetTop += (offsetParent as HTMLElement).offsetTop
		offsetParent = (offsetParent as HTMLElement).offsetParent
	}
	return offsetTop
}

export default ArtistPage
