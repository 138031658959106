import React, { useState, createRef, useEffect, useContext } from 'react'
import classnames from 'classnames'
import moment, { Moment } from 'moment'
import { LangContext } from '../../i18n/i18n'
import { artistPath, GenreType, formatShowBox } from '../../common'
import { Link } from 'gatsby'
import './ArtistPage.scss'
import { ArtistShow as ExtendedArtistShow } from '../../app.types'
import { MaterialButton } from '../../components/react-button'
import { BuyTicketsModalContext } from '../../components/BuyTickesModal/BuyTickesModal'

export type ArtistShow = Pick<
	ExtendedArtistShow,
	'url_friendly_identifier' | 'artistName' | 'start_time'
>

export interface PropsShort {
	date: Moment
	sessionName: string
	sessionType: string
	genreType: GenreType
}

export type Props = PropsShort & {
	nextShows: ArtistShow[]
}

export function ShowBoxShort({
	date,
	sessionName,
	sessionType,
	genreType,
}: PropsShort) {
	return (
		<div className="artist-page__showBox">
			<h6>{formatShowBox(date)}</h6>
			<h6>{sessionName}</h6>
			<h6>{sessionType}</h6>
			<BuyTicketsButton genreType={genreType} />
		</div>
	)
}

export function ShowBox(props: Props) {
	const { lang, t } = useContext(LangContext)
	const { sessionType, genreType, nextShows } = props
	return (
		<div className="artist-page__showBox-container">
			<ShowBoxShort {...props} />
			<div
				className={classnames('artist-page__showBox-nextSessions', genreType)}
			>
				{nextShows.map(
					({ start_time, artistName, url_friendly_identifier }) => (
						<div
							key={start_time}
							className={classnames('nextSessionBox', genreType)}
						>
							<div className="start_time">{start_time}</div>
							<div className="artistName">{artistName}</div>
							<Link
								className="artistLink"
								to={artistPath(lang, url_friendly_identifier)}
							>
								Artist Page
							</Link>
						</div>
					)
				)}
			</div>
		</div>
	)
}

export function BuyTicketsButton({ genreType }) {
	const { lang, t } = useContext(LangContext)
	const { setShow } = useContext(BuyTicketsModalContext)
	return (
		<MaterialButton
			className={classnames('getTicketsLink button button-prefill', genreType)}
			onClick={() => setShow(true)}
		>
			{t('program.sessioncard.buytickets')}
		</MaterialButton>
	)
}
