import './AboutPage.scss'

import React, { createRef, useContext, useEffect, useRef, useState } from 'react'

import { PictureBoardItem } from '../../app.types'
import { ColumnMenu } from '../../components/ColumnMenu/ColumnMenu'
import { Layout } from '../../components/layout'
import { MaterialButton } from '../../components/react-button'
import SEO from '../../components/seo'
import { LangContext, wrapLangProvider } from '../../i18n/i18n'
import { GatsbyPage, GatsbyPageProps } from '../../types'
import { useEffectOnce } from '../../utils'
import { columnMenuScrollToEventHandlerCreator, getScrollToEventHandler } from '../ArtistPage/ArtistPage'

export type Props = {
	festivalTeam: PictureBoardItem[]
	previousFestivals: { year: number; href: string }[]
	ourpartners: string[]
}

export type PageProps = GatsbyPageProps<Props>

export const AboutPage: GatsbyPage<Props> = wrapLangProvider(
	({ pageContext: { festivalTeam, previousFestivals, ourpartners } }) => {
		const { lang, t } = useContext(LangContext)
		const [value, setValue] = useState('about')

		const refs = {
			about: useRef<HTMLHeadingElement>(null),
			festivalteam: useRef<HTMLHeadingElement>(null),
			pastfestivals: useRef<HTMLHeadingElement>(null),
			partners: useRef<HTMLHeadingElement>(null),
		}
		const columnMenuOnChange = columnMenuScrollToEventHandlerCreator(refs)

		useEffect(() => {
			const eventHandler = getScrollToEventHandler(
				[
					{ refName: 'about', ref: refs.about },
					{ refName: 'festivalteam', ref: refs.festivalteam },
					{ refName: 'pastfestivals', ref: refs.pastfestivals },
					{ refName: 'partners', ref: refs.partners },
				],
				setValue
			)
			window.addEventListener('scroll', eventHandler)
			return () => {
				window.removeEventListener('scroll', eventHandler)
			}
		}, [])

		// TODO document hack
		useEffectOnce(() => {
			const stickyContainer = document.querySelector(
				'.about-page__menu-container'
			) as HTMLElement

			stickyContainer.style.height = document.body.clientHeight - 300 + 'px'
		})

		return (
			<React.Fragment>
				<Layout className="">
					<SEO title="About" />
					<div className="about-page__content grid">
						<div className="about-page__left onlyDesktop">
							<div className="about-page__menu-container onlyDesktop">
								<div className="about-page__menu-sticky">
									<div className="about-page__menu">
										<ColumnMenu
											onChange={columnMenuOnChange}
											value={value}
											buttons={[
												{
													value: 'about',
													displayText: t('about.menu.about'),
												},
												{
													value: 'festivalteam',
													displayText: t('about.menu.festivalteam'),
												},
												{
													value: 'pastfestivals',
													displayText: t('about.menu.pastfestivals'),
												},
												{
													value: 'partners',
													displayText: t('about.menu.partners'),
												},
											]}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="about-page__right">
							<section className="about-page__section">
								<h1 className="about-page__header" ref={refs.about}>
									{t('about.header')}
								</h1>
								<div
									className="about-page__section-content"
									dangerouslySetInnerHTML={{ __html: t('about.header.desc') }}
								/>
							</section>

							<section className="about-page__section">
								<h6 className="about-page__artisticcommitees-header">
									{t('about.artisticcommitees')}
								</h6>
								<div className="about-page__artisticcommitees-desc about-page__section-content">
									{t('about.artisticcommitees.desc')}
								</div>
							</section>

							<section className="about-page__section">
								<h6
									className="about-page__pictureboard-header"
									ref={refs.festivalteam}
								>
									{t('about.menu.festivalteam')}
								</h6>
								<div className="about-page__pictureboard-content about-page__section-content">
									{festivalTeam.map((t, i) => {
										return (
											<div className="about-page__pictureboard-member" key={i}>
												<img
													className="about-page__pictureboard-image"
													src={t.picture_absolute_path}
												/>
												<div className="about-page__pictureboard-right">
													<div className="about-page__pictureboard-rolename overline">
														{t.position}
													</div>
													<div className="about-page__pictureboard-name body1">
														{t.name}
													</div>
												</div>
											</div>
										)
									})}
								</div>
							</section>
						</div>
					</div>
				</Layout>
				<div className="about-page__hero app">
					<div className="about-page__pastfest container about-page__content grid">
						<div className="about-page__pastfest-container about-page__right">
							<h6 ref={refs.pastfestivals}>{t('about.menu.pastfestivals')}</h6>
							<div className="about-page__visitlastyear">
								{t('about.visitlastyear')}
							</div>
							<br />
							<div className="about-page__pastfest-buttons">
								{previousFestivals.map((pf, i) => (
									<MaterialButton
										className="about-page__pastfest-button"
										href={pf.href}
										target="_blank"
										key={i}
									>
										{pf.year}
									</MaterialButton>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="about-page about-page__ourpartners app container">
					<div className="about-page__content grid">
						<div className="about-page__right">
							<section className="about-page__section">
								<h6 ref={refs.partners}>{t('about.ourpartners')}</h6>
								<div className="about-page__ourpartners__content about-page__section-content">
									{ourpartners.map((o, i) => (
										<img
											key={i}
											className="about-page__ourpartners-image"
											src={o}
										/>
									))}
								</div>
							</section>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
)

export default AboutPage
