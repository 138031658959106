import React, { useState, useContext, useEffect } from 'react'
import classnames from 'classnames'
import { LangContext } from '../../i18n/i18n'
import './BuyTickesModal.scss'

import useScript from '../../hooks/useScript';

export interface Props {
	className?: string
	iframeUrl: string
}

// const iframeUrl = 'https://www.eventer.co.il/260v3?lpsec_poweredByBox_0=0&lpsec_banner_1_false=0&lpsec_purchaseBox_2=0&lpsec_eventDetails_3_false=0&lpsec_map_4_false=0&lpf_showBackground=false&lpf_showLocationDescription=false' // TODO

export const BuyTicketsModalContext = React.createContext<{
	show: boolean
	setShow: (show: boolean) => void
	iframeUrl: string,
	setIframeUrl: (url: string) => void,
}>({ show: false, setShow: undefined, iframeUrl: null, setIframeUrl: undefined })

export function BuyTickesModal({ className, iframeUrl }: Props) {
	const { t } = useContext(LangContext)
	const [ ticketsScriptLoaded, ticketsScriptError ] = useScript('https://www.eventer.co.il/js/vendor/iframeResizer.min.js');

	const finalIframeUrl = !iframeUrl ? "https://www.eventer.co.il/260v3?lpsec_poweredByBox_0=0&lpsec_banner_1_false=0&lpsec_purchaseBox_2=0&lpsec_eventDetails_3_false=0&lpsec_map_4_false=0&lpf_showBackground=false&lpf_showLocationDescription=false" : iframeUrl;

	useEffect(() => {
		if(ticketsScriptError)
			window.iFrameResize({checkOrigin: false}, "iframe.resizableFrame");
	}, [ticketsScriptLoaded]);

	return (
		<React.Fragment>
			<h6>{t('program.sessioncard.buytickets')}</h6>
			<iframe src={finalIframeUrl} width="100%" className="resizableFrame"></iframe>
		</React.Fragment>
	)
}

export function showTicketsModal() {}
