import React, { useContext } from 'react'
import { withPrefix } from 'gatsby';
import { Artist } from '../../app.types'
import './SessionCard.scss'
import { GenreType, artistPath } from '../../common'
import classnames from 'classnames'
import { LangContext } from '../../i18n/i18n'
import { BuyTicketsModalContext } from '../../components/BuyTickesModal/BuyTickesModal';
import { MaterialButton } from '../react-button'

export type ArtistData = Pick<Artist, 'name' | 'url_friendly_identifier'>

export type SessionCardShow = {
	startTime: string
	endTime: string
	artistData: ArtistData
}

export type SessionMetaData = {
	locationPlaceName: string
	locationGeoName: string
}

export type SessionData = SessionMetaData & {
	shows: SessionCardShow[]
	entrance_link: string | null
	
}
export type SessionCardProps = SessionData & {
	genreType: GenreType
}

export const SessionCard = ({
	locationPlaceName: name,
	locationGeoName: location,
	shows,
	genreType,
	entrance_link,
}: SessionCardProps) => {
	const { lang, t } = useContext(LangContext)
	const { setShow, setIframeUrl } = useContext(BuyTicketsModalContext)
	return (
		<div className={classnames('session-card card', genreType)}>
			<div className="session-card__content-container">
				<div className="session-card__topbar body2">
					<div className="session-card__topbar-left">{name}, {location}</div>
					{shows.length > 0 && <div className="session-card__topbar-right">{shows[0].startTime}-{shows[shows.length-1].endTime}</div>}
				</div>
				{shows.map((s, index) => {
					return (
						<React.Fragment key={index}>
							<div className="session-card__session">
								{/* <div className="session-card__session-time body2">
									{s.startTime} -{s.endTime}
								</div> */}
								<div className="session-card__session-bottom">
									<div className="session-card__session-bottom-left">
										{s.artistData.name}
									</div>
									<a
										className="session-card__session-bottom-right"
										href={withPrefix(artistPath(
											lang,
											s.artistData.url_friendly_identifier
										))}
									>
										{t('program.sessioncard.artistpage')}
									</a>
								</div>
							</div>
							<hr />
						</React.Fragment>
					)
				})}
			</div>
			
			<MaterialButton
				className={classnames('session-card__order-tickets-button', genreType)}
				onClick={() => {setShow(true); setIframeUrl(entrance_link)}}
			>
				{t('program.sessioncard.buytickets')}
			</MaterialButton>
		</div>
	)
}
