import React, { useState, useContext } from 'react'
import classnames from 'classnames'
import { LangContext } from '../../i18n/i18n'
import { tobase64 } from '../../common'

import './ColumnMenu.scss'
import { MaterialButton } from '../react-button'
export interface ColumnMenuProps<T> {
	startingValue?: T
	value?: T
	buttons: ColumnMenuButton<T>[]
	onChange?: (currentValue: T) => void
	className?: string
	inputWrapperComponent?: React.FC<{ value: T; displayText: string }>
}

export interface ColumnMenuButton<T> {
	value: T
	to?: string
	displayText: string
	ref?: React.RefObject<HTMLDivElement>
}

export function ColumnMenu<T>({
	startingValue,
	value,
	buttons,
	onChange,
	className,
	inputWrapperComponent: IWC,
}: ColumnMenuProps<T>) {
	let [checked, setChecked] = useState(startingValue || value)
	if (value) checked = value

	return (
		<div className={classnames('column-menu', className)}>
			{buttons.map(({ value, displayText, ref }, i) => {
				const id = tobase64(JSON.stringify(value))

				return (
					<div className={classnames({ active: value === checked })} ref={ref} key={i}>
						<MaterialButton className={value as any /* TODO */}>
							<input
								checked={checked === value}
								type="radio"
								name="tab"
								id={id}
								className={`tab` /* TODO: refactor */}
								onChange={e => {
									if (e.target.checked) {
										setChecked(value)
										if (onChange) onChange(value)
									}
								}}
							/>
							<label htmlFor={id}>{displayText}</label>
						</MaterialButton>
					</div>
				)
			})}
		</div>
	)
	function ConditionalWrap({ condition, wrap, children }) {
		return condition ? wrap(children) : children
	}
}

export const ColumnMenuRockJazz = ({ value, setChecked }) => {
	const { lang, t } = useContext(LangContext)

	return (
		<ColumnMenu
			value={value}
			onChange={setChecked}
			buttons={[
				{
					value: 'rock',
					displayText: t('common.genre.rockLong').toUpperCase(),
				},
				{
					value: 'jazz',
					displayText: t('common.genre.jazzLong').toUpperCase(),
				},
			]}
		/>
	)
}
