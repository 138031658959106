import React, { useState, useContext } from 'react'
import classnames from 'classnames'
import { Layout } from '../../components/layout'
import SEO from '../../components/seo'
import { GatsbyPageWrapper, GatsbyPageProps } from '../../types'

import { PressItem } from '../../app.types'
import { wrapLangProvider, LangContext } from '../../i18n/i18n'
import moment from 'moment'
import { formatRegular } from '../../common'
import './PressPage.scss'

export type Props = {
	pressItems: PressItem[]
}

export type PageProps = GatsbyPageProps<Props>

export const PressPage = wrapLangProvider(
	({ pageContext: { pressItems } }: GatsbyPageWrapper<Props>) => {
		const { lang, t } = useContext(LangContext)

		const byYear: { [year: string]: PressItem[] } = pressItems.reduce(
			(prev, curr) => {
				const date = moment(curr.date)
				if (!prev[date.get('year')]) prev[date.get('year')] = []
				prev[date.get('year')].push(curr)
				return prev
			},
			{}
		)

		return (
			<Layout className={classnames('press-page')}>
				<SEO title="Press" />
				<div className="content">
					<h2 className="press-page__header">{t('header.press')}</h2>
					<div className="press-page__press-sections">
						{Object.keys(byYear || {})
							.sort()
							.reverse()
							.map(year => {
								return (
									<div className="press-page__press-section" key={year}>
										<h4 className="press-page__yearHeader">{year}</h4>
										<div className="press-page__press-items">
											{byYear[year].map(
												({ date, href, title, pictureURL }, index) => (
													<a
														href={href}
														target="_blank"
														className="press-page__press-item"
														key={index}
													>
														<div className="press-page__press-item-logo">
															<img
																className="press-page__press-item-logo-img"
																src={pictureURL || require('./placeholder.png')}
															/>
														</div>
														<div className="press-page__press-item-date overline">
															{formatRegular(date)}
														</div>
														<div className="press-page__press-item-title body1">
															{title}
														</div>
													</a>
												)
											)}
										</div>
									</div>
								)
							})}
					</div>
				</div>
			</Layout>
		)
	}
)
export default PressPage
