import React, { useEffect, useContext, useState } from 'react'

require('../reset.css')
// layout.scss is a hack to allow importing the background image - styles reside at base.scss
require('./layout.scss')

import classnames from 'classnames'
import { Header } from './Header/Header'
import { LangContext } from '../i18n/i18n'
import {
	BuyTickesModal,
	BuyTicketsModalContext,
} from './BuyTickesModal/BuyTickesModal'
import Modal from 'react-modal'
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock'

Modal.setAppElement('#___gatsby')

export const Layout = ({ className, children }) => {
	const { lang, t } = useContext(LangContext)
	const [show, setShow] = useState(false)
	const [iframeUrl, setIframeUrl] = useState(null)

	useEffect(() => {
		enableBodyScroll(document.querySelector('.modal__overlay'))
		return () => disableBodyScroll(document.querySelector('.modal__overlay'))
	})

	return (
		<BuyTicketsModalContext.Provider value={{ show, setShow, iframeUrl, setIframeUrl }}>
			<Modal
				isOpen={show}
				onAfterOpen={() => null}
				onRequestClose={() => setShow(false)}
				overlayClassName="modal__overlay"
				className="modal__inner"
				bodyOpenClassName="ReactModal__Body--open"
				contentLabel="Buy Tickets"
				shouldCloseOnEsc={false}
				customStyles={{ direction: lang === 'he' ? 'rtl' : 'ltr' }}
			>
				<BuyTickesModal iframeUrl={iframeUrl} />
			</Modal>
			<div className={classnames('container app', className)}>
				<Header />
				{children}
			</div>
		</BuyTicketsModalContext.Provider>
	)
}
