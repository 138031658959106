// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-home-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\HomePage\\HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-home-page-tsx" */),
  "component---src-templates-artist-page-artist-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\ArtistPage\\ArtistPage.tsx" /* webpackChunkName: "component---src-templates-artist-page-artist-page-tsx" */),
  "component---src-templates-program-page-program-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\ProgramPage\\ProgramPage.tsx" /* webpackChunkName: "component---src-templates-program-page-program-page-tsx" */),
  "component---src-templates-talk-page-talk-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\TalkPage\\TalkPage.tsx" /* webpackChunkName: "component---src-templates-talk-page-talk-page-tsx" */),
  "component---src-templates-about-page-about-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\AboutPage\\AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-about-page-tsx" */),
  "component---src-templates-press-page-press-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\PressPage\\PressPage.tsx" /* webpackChunkName: "component---src-templates-press-page-press-page-tsx" */),
  "component---src-templates-lineup-page-lineup-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\LineupPage\\LineupPage.tsx" /* webpackChunkName: "component---src-templates-lineup-page-lineup-page-tsx" */),
  "component---src-templates-guests-page-guests-page-tsx": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\templates\\GuestsPage\\GuestsPage.tsx" /* webpackChunkName: "component---src-templates-guests-page-guests-page-tsx" */),
  "component---src-pages-404-js": () => import("C:\\Users\\Shlomi\\Desktop\\www\\showcase\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

