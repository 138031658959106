import React, { useContext, useEffect } from 'react'
import { Link, navigate, replace } from 'gatsby'
import classnames from 'classnames'

import whiteLogo from './white logo@2x.png'
import { MyLink } from '../../components/MyLink/MyLink'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { GatsbyPage } from '../../types'
import { LangContext, wrapLangProvider, defaultLang } from '../../i18n/i18n'

import './HomePage.scss'

const DateAndName = ({ className, date, name }) => (
	<div className={classnames('dateAndName', className)}>
		<div className="date">{date}</div>
		<div className="name">{name}</div>
	</div>
)

export const HomePage: GatsbyPage<{}> = wrapLangProvider(() => {
	useEffect(() => {
		if (!/\/[a-z][a-z]\/?/.test(window.location.href))
			replace('/' + defaultLang)
	})
	const { lang, t } = useContext(LangContext)

	return (
		<Layout className="home-page">
			<SEO title="Home" />
			<div className="hero">
				<div className="productionByWho">
					{/* <header>{t('home.aprodof')}</header> */}
					{/* <img src={whiteLogo} /> */}
					<h1 className="home__header">{t('home.mrworldwide')}</h1>
				</div>
				<div className="datesAndDescription">
					<div className="top">
						<DateAndName
							className="rockColor"
							date="13 - 17 NOV"
							name={t('common.genre.rockLong').toUpperCase()}
						/>
						<DateAndName
							className="jazzColor"
							date="20 - 23 NOV"
							name={t('common.genre.jazzLong').toUpperCase()}
						/>
					</div>
					<div className="bottom">{t('home.desc')}</div>
				</div>
			</div>
			<div className="bottom">
				<div className="lineupAnnounced">{t('home.lineupAnnounced')}</div>
				<div className="bottom-description">{t('home.bottomDesc')}</div>
				<div className="button-row">
					{/* TODO is using # and SEO concern? */}
					<MyLink
						to={`/${lang}/lineup/rock_indie_electronic`}
						className="button button-prefill rock"
					>
						{t('home.explorerock')}
					</MyLink>
					<MyLink
						to={`/${lang}/lineup/jazz_world`}
						className="button button-prefill jazz"
					>
						{t('home.explorejazz')}
					</MyLink>
				</div>
			</div>
		</Layout>
	)
})

export default HomePage
