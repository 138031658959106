import '../AboutPage/AboutPage.scss'
import './TalkPage.scss'

import React, { createRef, useContext, useEffect, useRef, useState } from 'react'

import { PictureBoardItem } from '../../app.types'
import { ColumnMenu } from '../../components/ColumnMenu/ColumnMenu'
import { Layout } from '../../components/layout'
import { MaterialButton } from '../../components/react-button'
import SEO from '../../components/seo'
import { LangContext, wrapLangProvider } from '../../i18n/i18n'
import { GatsbyPage, GatsbyPageProps } from '../../types'
import { columnMenuScrollToEventHandlerCreator, getScrollToEventHandler } from '../ArtistPage/ArtistPage'

import { GuestCard } from '../../components/GuestCard/GuestCard';
import { GenreType } from '../../common'

import programPoster from './program.jpg';

export type Props = {
	speakers: PictureBoardItem[]
	title: string
	content: string
}

export type PageProps = GatsbyPageProps<Props>

export const AboutPage: GatsbyPage<Props> = wrapLangProvider(
	({ pageContext: props }) => {
		const { lang, t } = useContext(LangContext)
		const sections = [
			{
				name: 'about',
				displayText: t('talk.menu.about'),
			},
			// {
			// 	name: 'register',
			// 	displayText: t('talk.menu.register'),
			// },
			// {
			// 	name: 'braindates',
			// 	displayText: t('talk.menu.braindates'),
			// },
			{
				name: 'speakers',
				displayText: t('talk.menu.speakers'),
			},
			{
				name: 'program',
				displayText: t('talk.menu.program'),
			},
		]
		const refTable = {
			about: useRef<any>(null),
			// register: useRef<any>(null),
			// braindates: useRef<any>(null),
			speakers: useRef<any>(null),
			program: useRef<any>(null),
		}
		const [value, setValue] = useState(sections[0].name)

		const columnMenuOnChange = columnMenuScrollToEventHandlerCreator(refTable)

		useEffect(() => {
			const eventHandler = getScrollToEventHandler(
				sections.map(({ name }) => ({ refName: name, ref: refTable[name] })),
				setValue
			)
			window.addEventListener('scroll', eventHandler)
			return () => {
				window.removeEventListener('scroll', eventHandler)
			}
		}, [])

		return (
			<Layout className="">
				<SEO title="Talk/Show/Case" />
				<div className="about-page__content grid">
					<div className="onlyDesktop talk-page__left">
						<div className="about-page__menu-sticky about-page__menu">
							<ColumnMenu
								onChange={columnMenuOnChange}
								value={value}
								buttons={sections.map(({ name, displayText }) => ({
									value: name,
									displayText,
								}))}
							/>
						</div>
					</div>
					<div className="about-page__right">
						<section className="about-page__section">
							<h1 className="about-page__header" ref={refTable.about}>
								{props.title}
							</h1>
							<div
								className="about-page__section-content"
								dangerouslySetInnerHTML={{
									__html: props.content,
								}}
							/>
						</section>

						{/* <section className="about-page__section">
							<h6 ref={refTable.register}>{t('talk.menu.register')}</h6>
							<div className="about-page__section-content">
								<iframe
									height="1600px"
									src="https://showcase.forms-wizard.co.il/users/new"
									width="100%"
									frameBorder="0"
									scrolling="no"
									id="register"
								/>
							</div>
						</section> */}

						{/* <section className="about-page__section">
							<h6 ref={refTable.braindates}>{t('talk.menu.braindates')}</h6>
							<div className="about-page__pictureboard-content about-page__section-content"></div>
						</section> */}
						<section className="about-page__section">
							<h6 ref={refTable.speakers}>{t('talk.menu.speakers')}</h6>
							<div className="about-page__pictureboard-content about-page__section-content">
								{props.speakers.map((t, i) => {

									const guestCardProps = {
										name: t.name,
										industryRole: t.position,
										description: t.description,
										pictureURL: t.picture_absolute_path,
										genreType: "rock" as GenreType,
									};

									return (
										<GuestCard guest={guestCardProps} />
									);

									return (
										<div className="about-page__pictureboard-member" key={i}>
											<img
												className="about-page__pictureboard-image"
												src={t.picture_absolute_path}
											/>
											<div className="about-page__pictureboard-right">
												<div className="about-page__pictureboard-rolename overline">
													{t.position}
												</div>
												<div className="about-page__pictureboard-name body1">
													{t.name}
												</div>
												<div className="about-page__pictureboard-description">
													Description
												</div>
											</div>
										</div>
									)
								})}
							</div>

							<MaterialButton
								className="talk-page__schedule-btn button button-prefill rock"
								href="https://brain-dates.forms-wizard.co.il/"
								target="_blank"
							>
								{t('talk.schedule')}
							</MaterialButton>
						</section>

						<section className="about-page__section">
							<h6 ref={refTable.program}>{t('talk.menu.program')}</h6>
							<div className="about-page__section-content">
								<img src={programPoster} />
							</div>
						</section>
					</div>
				</div>
			</Layout>
		)
	}
)

export default AboutPage
