import React, { useState, useContext } from 'react'
import classnames from 'classnames'
import { Layout } from '../../components/layout'
import SEO from '../../components/seo'
import { ColumnMenuRockJazz } from '../../components/ColumnMenu/ColumnMenu'
import { GatsbyPageWrapper } from '../../types'
import {
	GenreType,
	artistPath,
	navigate,
	genreTypeToDisplayText,
	xorGenreType,
} from '../../common'
import { Artist } from '../../app.types'
import { LineupBox } from './LineupBox'
import { LangContext, wrapLangProvider } from '../../i18n/i18n'
import './LineupPage.scss'

export type LineupPageProps = {
	initialGenreType?: GenreType
	artistsByType: ArtistsByType[]
}

export type ArtistsByType = { genre: GenreType; artists: Artist[] }

export const LineupPage = wrapLangProvider(
	({
		pageContext: { initialGenreType: genreType = 'rock', artistsByType },
	}: GatsbyPageWrapper<LineupPageProps>) => {
		const { lang, t } = useContext(LangContext)

		return (
			<Layout className={classnames('lineup-page', { [genreType]: true })}>
				<SEO title={t('lineup.seo.title')} />
				<div className="content">
					<header>
						<div className="headerText">{t('lineup.header')}</div>
						<ColumnMenuRockJazz
							value={genreType}
							setChecked={() =>
								navigate(
									lang,
									'/lineup/' + genreTypeToDisplayText(xorGenreType(genreType))
								)
							}
						/>
					</header>
					{artistsByType.map(({ genre, artists }, index) => (
						<section
							key={index}
							className={classnames(`lineup-section ${genre}`, {
								['inactive']: genre !== genreType,
							})}
						>
							{artists.map((artist, index) => (
								<LineupBox
									key={index}
									genreType={artist.genreType}
									name={artist.name}
									backgroundImage={artist.cover_picture_absolute_path}
									url_friendly_identifier={artistPath(lang, artist)}
									ticketsLink={'#'}
								/>
							))}
						</section>
					))}
				</div>
			</Layout>
		)
	}
)

export default LineupPage
