import { useEffect, useRef } from 'react'

export function wrap<T extends Function>(fn: T, before: any): T {
	return <any>function(...args) {
		before(...args)
		return fn(...args)
	}
}

export function useEffectOnce(fn) {
	const once = useRef(false)
	useEffect(() => {
		if (!once.current) {
			once.current = true
			fn()
		}
	})
}
